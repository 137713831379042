@charset "utf-8";

@import "so-simple";

$light-pink: #ff4d8b !default;

.entry-title {
    color: $light-pink;
}

.page-title {
    color: $light-pink;
}

.page-sidebar {
    color: $light-pink;
}

.site-title a {
    color: $light-pink !important;
}

.highlights {
    h1, .entry-title {
        font-size: 24px !important;
    }

    .entry {
        padding-bottom: 0px;
    }
}

.highlights-grid {
    grid-gap: 1em !important;
    @extend .entries-grid;    
}

.btn--bluesky {
    background-color: #00bfff;
    color: #fff;
    &:hover {
        background-color: #007399;
        color: #fff;
    }
}